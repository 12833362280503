import axios from "axios";

export const getCittadini = async ()=>{
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/cittadini`,{
        headers:{
            section: "cittadini",
        }
    })
}
export const getCittadinoById = async (id)=>{
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/cittadini?id=${id}`,{
        headers:{
            section: "cittadini",
        }
    })
}
export const editCittadino = async (id,payload) => {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/cittadini?id=${id}`,payload,{
        headers:{
            section: "cittadini",
        }
    })
}

