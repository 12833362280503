import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { resetPassword } from "../../../services/Auth";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	progressContainer: {
		paddingTop: theme.spacing(2),
	},
	link: {
		cursor: "pointer",
	},
}));

const ForgotPassword = ({ fireBackClick }) => {
	const classes = useStyles();
	const [username, setUsername] = React.useState();
	const [result, setResult] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	const handleReset = async event => {
		event.preventDefault();
		setLoading(true);
		try {
			await resetPassword(username);
			setLoading(false);
			setResult("E' stata inviata un'email all'indirizzo indicato.");
		} catch (err) {
			setLoading(false);
			setResult(
				"Si è verificato un errore durante l'invio della richiesta. Riprovare più tardi."
			);
		}
	};

	return (
		<>
			<Typography component="h1" variant="h5">
				Resetta la tua password
			</Typography>
			<form className={classes.form} noValidate onSubmit={handleReset}>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email"
					name="email"
					autoComplete="email"
					autoFocus
					onChange={event => setUsername(event.target.value)}
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					Reset Password
				</Button>
				<Grid container>
					<Grid item xs>
						<Link variant="body2" onClick={() => fireBackClick()}>
							<span className={classes.link}>Torna alla pagina di accesso</span>
						</Link>
					</Grid>
				</Grid>
				<div className={classes.progressContainer}>
					{loading ? <CircularProgress /> : null}
					{result}
				</div>
			</form>
		</>
	);
};

export default ForgotPassword;
