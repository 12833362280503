import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	container: {
		width: props => (props.width ? props.width : "100%"),
		padding: props => (props.padding ? props.padding : theme.spacing(1)),
		borderTopRightRadius: props => (props.internalTitle ? 0 : "5px"),
		borderTopLeftRadius: props => (props.internalTitle ? 0 : "5px"),
		backgroundColor: "#fff",
		borderTop: `1px solid ${theme.palette.grey[200]}`,
		borderLeft: `1px solid ${theme.palette.grey[200]}`,
		borderRight: `1px solid ${theme.palette.grey[200]}`,
		position: "relative",
	},
	footer: {
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.grey[200]}`,
		backgroundColor: "#fff",
		borderBottomLeftRadius: "5px",
		borderBottomRightRadius: "5px",
	},
	titleContainer: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	title: {
		fontSize: "1.5rem",
	},
	internalTitleContainer: {
		padding: theme.spacing(1),
		borderTopRightRadius: "5px",
		borderTopLeftRadius: "5px",
		backgroundColor: "#fff",
		borderTop: `1px solid ${theme.palette.grey[200]}`,
		borderLeft: `1px solid ${theme.palette.grey[200]}`,
		borderRight: `1px solid ${theme.palette.grey[200]}`,
	},
}));

const Box = props => {
	const classes = useStyles(props);
	const {
		children,
		title,
		internalTitle = null,
		className = "",
		style,
		spaced,
	} = props;
	const styles = spaced ? { ...style, marginTop: 10 } : style;
	return (
		<div style={{ ...styles }} className={className}>
			{title ? (
				<div className={classes.titleContainer}>
					<strong className={classes.title}>{title}</strong>
				</div>
			) : null}
			{internalTitle ? (
				<div className={classes.internalTitleContainer}>
					<strong>{internalTitle}</strong>
				</div>
			) : null}
			<div className={classes.container}>{children}</div>
			<Footer />
		</div>
	);
};

const Footer = () => {
	const classes = useStyles();
	return <div className={classes.footer}></div>;
};

export default Box;
