import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getTickets } from "../../services/ComunitaEnergetica/";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Datatable from "../../components/Datatable/";
import ViewListIcon from "@material-ui/icons/ViewList";
const useStyles = makeStyles(theme => ({
	title: {
		margin: 0,
	},
	tableContainer: {
		padding: "20px 0",
	},
	progressContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

export default function ComunitaEnergetica() {
	const classes = useStyles();
	const history = useHistory();
	const [data, setData] = useState();

	// const columns = [
	// 	{
	// 		title: "Data d'apertura",
	// 		field: "dataCreazione",
	// 		editable: "never",
	// 		defaultSort: "desc",
	// 		render: rowData => moment(rowData.dataCrazione).format("DD/MM/YYYY"),
	// 	},
	// 	{ title: "Codifce fiscale", field: "anagrafica.cf", editable: "never" },
	// 	{ title: "Cognome", field: "anagrafica.cognome", editable: "never" },
	// 	{ title: "Nome", field: "anagrafica.nome", editable: "never" },
	// 	{
	// 		title: "Data di nascita",
	// 		field: "dataDiNascita",
	// 		editable: "never",
	// 		render: rowData =>
	// 			moment(rowData.anagrafica.dataDiNascita).format("DD/MM/YYYY"),
	// 	},
	// 	{
	// 		title: "Luogo di nascita",
	// 		field: "anagrafica.luogoDiNascita",
	// 		editable: "never",
	// 	},
	// 	{ title: "Indirizzo", field: "anagrafica.residenza", editable: "never" },
	// 	{ title: "Telefono", field: "anagrafica.telefono", editable: "never" },
	// ];

	const columns = [
		{
			title: "Autore",
			field: "autore",
			editable: "never",
		},
		{
			title: "Numero di immobili",
			field: "immobili",
			editable: "never",
			render: rowData => rowData.immobili.length,
		},
		{
			title: "Note",
			field: "note",
			editable: "never",
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			const response = await getTickets();
			setData(response.data);
		};

		fetchData();
	}, []);

	if (data) {
		return (
			<Container maxWidth="xl">
				<div className={classes.tableContainer}>
					<Datatable
						data={data}
						columns={columns}
						editable={false}
						title="Comunità energetica"
						actions={[
							{
								icon: () => <ViewListIcon />,
								tooltip: "Dettaglio richiesta",
								isFreeAction: false,
								onClick: (event, rowData) => {
									history.push(`/comunita-energetica/${rowData._id}`);
								},
							},
						]}
					/>
				</div>
			</Container>
		);
	}

	return (
		<Container classes={{ root: classes.progressContainer }} maxWidth="xl">
			<CircularProgress />
		</Container>
	);
}
