import React, { useState, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getSession } from "react-session-persist";
import { Link, useHistory } from "react-router-dom";
import { DateRange } from "react-date-range";
import { createProject } from "../../../services/Baratto";
import { purple } from "@material-ui/core/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/ArrowBack";
import Container from "@material-ui/core/Container";
import JoditEditor from "jodit-react";
import Box from "../../../components/Box";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import it from "date-fns/locale/it";
import { Tooltip, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	progress: {
		minWidth: 300,
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		display: "flex",
		justifyContent: "space-between",
	},
	header: {
		width: "70%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		fontSize: "1rem",
		fontWeight: 500,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	main: {
		display: "flex",
		justifyContent: "space-between",
	},
	editor: {
		width: "70%",
	},
	mainBox: {
		width: "70%",
		"& strong": {
			color: theme.palette.grey[700],
		},
	},
	side: {
		display: "flex",
		flexDirection: "column",
		width: "25%",
	},
	documents: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
	},
	calendar: {
		width: "100%",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	backIcon: {
		textDecoration: "none",
		color: theme.palette.grey[800],
	},
	message: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		"& button": {
			color: "#fff",
		},
	},
}));

const ColorButton = withStyles(theme => ({
	root: {
		color: theme.palette.getContrastText(purple[400]),
		backgroundColor: purple[400],
		"&:hover": {
			backgroundColor: purple[600],
		},
	},
}))(Button);

export default function NuovoProgetto() {
	const editor = useRef(null);
	const classes = useStyles();
	const [content, setContent] = useState("");
	const [documents, setDocuments] = useState([]);
	const [title, setTitle] = useState("");
	const [isee, setIsee] = useState();
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);
	const [open, setOpen] = useState(false);
	const [created, setCreated] = useState("not-started");
	const session = getSession();
	const history = useHistory();

	const config = {
		readonly: false,
		autofocus: false,
		disablePlugins: "font",
		uploader: {
			insertImageAsBase64URI: true,
			url: `${process.env.REACT_APP_API_ENDPOINT}/news/allegati`,
			headers: {
				Authorization: `Bearer ${session.token}`,
			},
		},
		filebrowser: {
			ajax: {
				url: `${process.env.REACT_APP_API_ENDPOINT}/news/allegati`,
				headers: {
					Authorization: `Bearer ${session.token}`,
				},
			},
			uploader: {
				url: `${process.env.REACT_APP_API_ENDPOINT}/news/allegati`,
				headers: {
					Authorization: `Bearer ${session.token}`,
				},
			},
		},
		buttons: [
			"source",
			"|",
			"bold",
			"strikethrough",
			"underline",
			"italic",
			"eraser",
			"|",
			"superscript",
			"subscript",
			"|",
			"ul",
			"ol",
			"|",
			"outdent",
			"indent",
			"|",
			"fontsize",
			"brush",
			"paragraph",
			"|",
			"image",
			"file",
			"video",
			"table",
			"link",
			"|",
			"align",
			"undo",
			"redo",
			"\n",
			"selectall",
			"cut",
			"copy",
			"paste",
			"copyformat",
			"|",
			"hr",
			"symbol",
			"fullsize",
			"print",
			"preview",
			"find",
			"about",
		],
		height: 400,
	};

	const addDocument = () => {
		setDocuments([...documents, { id: Math.random().toString(36), name: "" }]);
	};
	const handleDocumentChanged = (event, id) => {
		const selectedDocument = documents.find(document => document.id === id);
		const updatedDocuments = [...documents];
		updatedDocuments[updatedDocuments.indexOf(selectedDocument)].name =
			event.target.value;
		setDocuments(updatedDocuments);
	};
	const handleAmount = event => setIsee(event.target.amout);
	const createNewProject = async () => {
		setOpen(true);
		try {
			const response = await createProject(
				title,
				content,
				documents.map(document => document.name),
				dateRange[0].startDate,
				dateRange[0].endDate,
				isee
			);
			console.log(response);
			setCreated("success");
		} catch (err) {
			setCreated("error");
		}
	};

	return (
		<Container maxWidth="xl">
			<Link to="/partenariato" className={classes.backIcon}>
				<Tooltip
					title="Torna indietro"
					aria-label="Torna indietro"
					placement="right"
				>
					<ArrowBack />
				</Tooltip>
			</Link>
			<div className={classes.header}>
				<Typography variant="h4">Crea nuovo progetto sociale</Typography>
				<ColorButton
					variant="contained"
					disabled={!title.length}
					onClick={createNewProject}
				>
					Crea
				</ColorButton>
			</div>
			<div className={classes.container}>
				<div className={classes.mainBox}>
					<Box padding={15}>
						<TextField
							id="title"
							label="Titolo"
							type="text"
							variant="outlined"
							fullWidth
							value={title}
							onChange={event => setTitle(event.target.value)}
						/>
						<div style={{ marginTop: 15 }}>
							<strong>Descrizione progetto</strong>
							<div style={{ marginTop: 10 }}>
								<JoditEditor
									ref={editor}
									value={content}
									config={config}
									tabIndex={1} // tabIndex of textarea
									onBlur={content => setContent(content.target.innerHTML)} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
						</div>
					</Box>
				</div>
				<div className={classes.side}>
					<Box padding={15} internalTitle="Documenti richiesti">
						<div className={classes.documents}>
							{documents.map(document => (
								<Document
									id={document.id}
									name={document.name}
									key={document.id}
									fireDocumentChanged={event =>
										handleDocumentChanged(event, document.id)
									}
								/>
							))}
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								startIcon={<AddIcon />}
								onClick={addDocument}
							>
								Aggiungi
							</Button>
						</div>
					</Box>
					<Box
						padding={15}
						internalTitle="Limite ISEE"
						style={{ marginTop: 10 }}
					>
						<FormControl
							fullWidth
							className={classes.margin}
							variant="outlined"
						>
							<InputLabel htmlFor="isee">Quantità</InputLabel>
							<OutlinedInput
								id="isee"
								value={isee}
								type="number"
								onChange={handleAmount}
								startAdornment={
									<InputAdornment position="start">€</InputAdornment>
								}
								labelWidth={60}
							/>
						</FormControl>
					</Box>
					<Box
						padding={15}
						internalTitle="Durata progetto"
						style={{ marginTop: 10 }}
					>
						<DateRange
							className={classes.calendar}
							editableDateInputs={false}
							onChange={item => setDateRange([item.selection])}
							moveRangeOnFirstSelection={false}
							ranges={dateRange}
							lang="it"
							locale={it}
						/>
					</Box>
				</div>
			</div>
			<Backdrop
				className={classes.backdrop}
				open={open}
				onClick={() => setOpen(false)}
			>
				{created === "not-started" ? (
					<CircularProgress color="inherit" />
				) : created === "success" ? (
					<div className={classes.message}>
						<Typography variant="h3">
							Il progetto è stato creato con successo.
						</Typography>
						<Button
							variant="text"
							className={classes.button}
							startIcon={<BackIcon />}
							onClick={() => history.push("/partenariato")}
						>
							Torna indietro
						</Button>
					</div>
				) : created === "error" ? (
					<div className={classes.message}>
						<Typography variant="h3">
							Si è verificato un errore nella creazione del progetto.
						</Typography>
						<Button
							variant="text"
							className={classes.button}
							startIcon={<BackIcon />}
							onClick={() => history.push("/partenariato")}
						>
							Torna indietro
						</Button>
					</div>
				) : null}
			</Backdrop>
		</Container>
	);
}

const Document = ({ id, name, fireDocumentChanged }) => {
	return (
		<TextField
			id={id}
			value={name}
			label="Documento"
			variant="outlined"
			style={{ margin: "10px 0" }}
			onChange={fireDocumentChanged}
		/>
	);
};
