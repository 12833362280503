import axios from "axios";
import { getSession } from "react-session-persist";

export const editPassword = (oldPassword, newPassword) => {
	const session = getSession();
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/utenti/self`,
		{
			oldPassword: oldPassword,
			password: newPassword,
		},
		{
			headers: {
				Authorization: `Bearer ${session.token}`,
				section: "utenti",
			},
		}
	);
};

export const editUserInfo = payload => {
	const session = getSession();
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/utenti/self`,
		payload,
		{
			headers: {
				Authorization: `Bearer ${session.token}`,
				section: "utenti",
			},
		}
	);
};
