import React from "react";
import { compose, withProps } from "recompose";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from "react-google-maps"; //TODO: Aggiornare con versione più nuove, vedi https://react-google-maps-api-docs.netlify.app/

const Map = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `400px` }} />,
		mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)(props => {
	let map = React.useRef();
	const { lat, lng, marker } = props;
	// const [setMarker] = React.useState();
	const [zoom, setZoom] = React.useState(17);
	const handleZoom = () => setZoom(map.getZoom());
	return (
		<GoogleMap
			ref={ref => (map = ref)}
			defaultZoom={zoom}
			defaultCenter={{ lat, lng }}
			onZoomChanged={handleZoom}
		>
			{marker ? <Marker position={{ lat, lng }} /> : ""}
		</GoogleMap>
	);
});

export default Map;
