import React from "react";
import Dialog from "../../../components/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function ClosureDialog({
	open,
	handleCloseDialog,
	handleSave,
	closureState,
	handleChangeClosureState,
	handleChangeNote,
	note,
	classes,
}) {
	return (
		<Dialog
			open={open}
			onClose={handleCloseDialog}
			onSave={handleSave}
			title="Chiusura segnalazione"
			buttonTitle="Salva"
		>
			<div className={classes.dialogContainer}>
				<strong className={classes.selectTitle}>
					Selezionare esito chiusura.
				</strong>
				<FormControl className={classes.formControl}>
					<InputLabel id="closure-state">Stato</InputLabel>
					<Select
						labelId="closure-state"
						id="closure"
						value={closureState}
						onChange={handleChangeClosureState}
					>
						<MenuItem value={4}>SUCCESSO</MenuItem>
						<MenuItem value={5}>INSUCCESSO</MenuItem>
						<MenuItem value={7}>GIA' RIPARATO</MenuItem>
						<MenuItem value={8}>NON DI COMPETENZA</MenuItem>
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<strong>Nota</strong>
					<TextareaAutosize
						rowsMax={4}
						aria-label="nota"
						placeholder={note ? note : "..."}
						autoComplete="true"
						rowsMin={4}
						className={classes.textArea}
						onChange={handleChangeNote}
					/>
				</FormControl>
			</div>
		</Dialog>
	);
}
