import { getSession, saveSession, removeSession } from "react-session-persist";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import React from "react";

export const getStateFromBaseValue = value => {
	switch (value) {
		case 0:
			return "APERTA";
		case 1:
			return "IN ATTESA";
		case 2:
			return "PRESA IN CARICO";
		case 3:
			return "IN LAVORAZIONE";
		case 4:
			return "CONCLUSA CON SUCCESSO";
		case 5:
			return "CONCLUSA CON INSUCCESSO";
		case 7:
			return "GIA' RIPARATO";
		case 8:
			return "NON DI COMPETENZA";
		default:
			return;
	}
};

export const jwt_decode = token => {
	return JSON.parse(atob(token.split(".")[1]));
};

export const permissions = {
	ADMIN: 0, //può aggiungere utenti, creare gruppi all'interno della sezione
	DELETE: 1, // può usare get/post/delete
	READWRITE: 2, // può usare get/post
	READONLY: 3, //può fare solo get
};

export const isEmpty = obj =>
	Object.keys(obj).length === 0 && obj.constructor === Object;

export const initializeInterceptor = async () => {
	const session = getSession();
	const logic = async failedRequest => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/refreshToken`,
				{
					headers: {
						Authorization: `Bearer ${session.refreshToken}`,
					},
				}
			);
			await saveSession({
				token: response.data.token,
				refreshToken: session.refreshToken,
				...jwt_decode(response.data.token),
			});
			failedRequest.response.config.headers[
				"Authorization"
			] = `Bearer ${response.data.token}`;
		} catch (err) {
			if (
				+jwt_decode(session.refreshToken).exp <
				+Date.now().toString().slice(0, 10)
			) {
				console.error("refresh token expired");
				await removeSession();
			}
		}
	};
	createAuthRefreshInterceptor(axios, logic);
};

export const getBase64 = (file, callback) => {
	let reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		callback(reader.result);
	};
	reader.onerror = function (error) {
		console.error("Error: ", error);
	};
};

export const renderListWithEllipsis = (list,field,maxItems=2)=>{
	return (
		<ul>
			{
				list.map(
				(item, index) =>
					index <= maxItems ?
						<li>{index > maxItems - 1 ? "..." : item[field]}</li>
						: "")
			}
		</ul>
	)
}
