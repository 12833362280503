import axios from "axios";
import { getSession } from "react-session-persist";

export const getRequests = async () => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/barattosociale/richieste`
	);
};

export const getRequest = async id => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/barattosociale/richieste?id=${id}`
	);
};

export const getRequestAttachment = async attachmentId => {
	const session = getSession();
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/barattosociale/allegati?id=${attachmentId}`,
		{
			headers: {
				Authorization: `Bearer ${session.token}`,
			},
			responseType: "blob",
		}
	);
};

export const createProject = async (
	title,
	description,
	requestedAttachments = [],
	startDate,
	endDate,
	iseeLimit
) => {
	return axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/barattosociale/progetti`,
		{
			titolo: title,
			descrizione: description,
			allegatiRichiesti: requestedAttachments,
			inizioProgetto: startDate,
			fineProgetto: endDate,
			limiteIsee: iseeLimit,
		}
	);
};
