import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import { getArticles } from "../../services/Articoli";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";

import Datatable from "../../components/Datatable/";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
  },
  tableContainer: {
    padding: "20px 0",
  },
  progressContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function Articoli() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const columns = [
    { title: "Titolo", field: "titolo", editable: "never" },
    {
      title: "Data di creazione",
      field: "data_news",
      editable: "never",
      defaultSort: "desc",
      render: rowData =>
        moment(rowData.dataCreazione).format("DD/MM/YYYY HH:ss"),
    },
    { title: "Categoria", field: "categoria", editable: "never" },
    {
      title: "Ultima modifica",
      field: "ultimaModifica",
      editable: "never",
      render: rowData =>
        moment(rowData.ultimaModifica).format("DD/MM/YYYY HH:ss"),
    },
    {
      title: "Stato",
      field: "isPubblicato",
      editable: "never",
      render: rowData => (rowData.isPubblicato ? "Pubblicato" : "Bozza"),
    },
    {
      title: "In evidenza",
      field: "isPinned",
      editable: "never",
      render: rowData => (rowData.isPinned ? <DoneIcon /> : <ClearIcon />),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await getArticles();
      setData(response.data);
    };
    fetchData();
  }, []);

  const ColorButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(purple[400]),
      backgroundColor: purple[400],
      "&:hover": {
        backgroundColor: purple[600],
      },
    },
  }))(Button);

  const handleCreateArticle = () => {
    history.push("/articoli/nuovo");
  };

  if (data) {
    return (
      <Container maxWidth="xl">
        <div className={classes.buttonContainer}>
          <ColorButton variant="contained" onClick={handleCreateArticle}>
            Crea nuovo articolo
          </ColorButton>
        </div>
        <div className={classes.tableContainer}>
          <Datatable
            data={data}
            columns={columns}
            title="Articoli"
            editable={false}
            actions={[
              {
                icon: () => <EditIcon />,
                tooltip: "Modifica articolo",
                isFreeAction: false,
                onClick: (event, rowData) => {
                  history.push(`/articoli/${rowData._id}`);
                },
              },
            ]}
          />
        </div>
      </Container>
    );
  }

  return (
    <Container classes={{ root: classes.progressContainer }} maxWidth="xl">
      <CircularProgress />
    </Container>
  );
}
