import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getRequests } from "../../services/Baratto/";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import ViewListIcon from "@material-ui/icons/ViewList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Datatable from "../../components/Datatable/";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { Check, Close } from "@material-ui/icons";
const useStyles = makeStyles(theme => ({
	title: {
		margin: 0,
	},
	tableContainer: {
		padding: "20px 0",
	},
	progressContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
	},
}));

export default function Baratto() {
	const classes = useStyles();
	const history = useHistory();
	const [data, setData] = useState();

	const columns = [
		{ title: "Progetto", field: "idProgetto.titolo", editable: "never" },
		{
			title: "Data d'apertura",
			field: "dataCreazione",
			editable: "never",
			defaultSort: "desc",
			render: rowData =>
				moment(rowData.dataCreazione).format("dddd DD MMMM YYYY"),
		},
		{ title: "Autore", field: "autore", editable: "never" },
		{
			title: "Persona giuridica",
			field: "anagrafica",
			editable: "never",
			render: rowData => (rowData.anagrafica ? <Check /> : <Close />),
		},
		// {
		// 	title: "Indirizzo",
		// 	field: "anagrafica.residenza",
		// 	editable: "never",
		// },
	];

	const ColorButton = withStyles(theme => ({
		root: {
			color: theme.palette.common.white,
			backgroundColor: green[400],
			"&:hover": {
				backgroundColor: green[600],
			},
		},
	}))(Button);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getRequests();
			setData(response.data);
		};

		fetchData();
	}, []);

	const handleCreateProject = () => {
		history.push("/progetto/nuovo");
	};

	if (data) {
		return (
			<Container maxWidth="xl">
				<div className={classes.buttonContainer}>
					<ColorButton variant="contained" onClick={handleCreateProject}>
						Crea nuovo progetto
					</ColorButton>
				</div>
				<div className={classes.tableContainer}>
					<Datatable
						data={data}
						columns={columns}
						title="Partenariato Sociale"
						actions={[
							{
								icon: () => <ViewListIcon />,
								tooltip: "Dettaglio richiesta",
								isFreeAction: false,
								onClick: (event, rowData) => {
									history.push(`/partenariato/${rowData._id}`);
								},
							},
						]}
					/>
				</div>
			</Container>
		);
	}

	return (
		<Container classes={{ root: classes.progressContainer }} maxWidth="xl">
			<CircularProgress />
		</Container>
	);
}
