import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "fontsource-roboto";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import Session from "react-session-persist";
import auth from "../src/store/reducers/auth";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AuthProvider } from "./context/Auth";
import { ToastProvider } from "./context/Toast";
const loggerMiddleware = createLogger();
const store = createStore(
	auth,
	applyMiddleware(
		thunkMiddleware, // lets us dispatch() functions
		loggerMiddleware // neat middleware that logs actions
	)
);

ReactDOM.render(
	<Provider store={store}>
		<Router history={createBrowserHistory()}>
			<Session>
				<CssBaseline />
				<ToastProvider>
					<AuthProvider>
						<App />
					</AuthProvider>
				</ToastProvider>
			</Session>
		</Router>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
