import axios from "axios";
import moment from "moment";

export const createAppointment = payload => {
	return axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/appuntamenti`,
		payload
	);
};

export const batch = async (payload, method) => {
	return axios({
		method: method,
		url: `${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/appuntamenti/batch`,
		data: payload,
	});
};

export const getAppointments = (start, end, showDeleted = false) => {
	const startDate = start ? start : moment().startOf("week").toISOString();
	const endDate = end ? end : moment().endOf("week").toISOString();

	console.log("startDate", startDate);
	console.log("endDate", endDate);

	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/appuntamenti/self?startDate=${startDate}&endDate=${endDate}&showDeleted=${showDeleted}`
	);
};

export const getAllAppointments = (start, end) => {
	const startDate = start ? start : moment().startOf("week").toISOString();
	const endDate = end ? end : moment().endOf("week").toISOString();
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/appuntamenti?startDate=${startDate}&endDate=${endDate}`
	);
};

export const updateAppointment = id => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/appuntamenti?id=${id}`
	);
};

export const getUserDisponibility = () => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/disponibilita/self`
	);
};

export const addUserDisponibility = payload => {
	return axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/disponibilita`,
		payload
	);
};

export const editUserDisponibility = payload => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/disponibilita`,
		payload
	);
};

export const getMembers = () => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/appuntamenti/appuntamenti/members`
	);
};
