import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getUsers } from "../../../services/Auth";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import { CircularProgress } from "@material-ui/core";
import { assignTicket } from "../../../services/Segnalazioni";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    maxHeight: 400,
    backgroundColor: theme.palette.background.paper,
  },
  main: {
    minWidth: 300,
  },
  avatar: {
    textTransform: "uppercase",
  },
  text: {
    textTransform: "capitalize",
  },
}));

export default function AssignmentDialog({
  open,
  handleClose,
  onSave,
  category,
  section,
  ticketId,
}) {
  const [users, setUsers] = useState([]);
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.splice(currentIndex, 1);
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAssign = async () => {
    const response = await assignTicket(category, ticketId, checked[0]._id);
    console.log("response", response);
    onSave(response.data);
  };

  useEffect(() => {
    const get = async () => {
      const response = await getUsers(category, section);
      setUsers(response.data);
    };
    get();
  }, [category, section]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Assegna segnalazione</DialogTitle>
      <DialogContent classes={{ root: classes.main }}>
        {!users.length ? (
          <CircularProgress />
        ) : (
          <List dense className={classes.root}>
            {users.map(value => {
              const labelId = `checkbox-list-secondary-label-${value._id}`;
              return (
                <ListItem key={value._id} button>
                  <ListItemAvatar>
                    <Avatar
                      classes={{ root: classes.avatar }}
                      alt={`User ${value.username}`}>
                      {value.username
                        .split(" ")
                        .map(name => name[0])
                        .join("")
                        .toString()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ root: classes.text }}
                    id={labelId}
                    primary={`${value.username}`}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(value)}
                      checked={checked.indexOf(value) !== -1}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annulla
        </Button>
        <Button
          onClick={handleAssign}
          color="primary"
          disabled={!checked.length}
          autoFocus>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
}
