import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertDialog({
	open,
	handleClose,
	handleSave,
	operation,
}) {
	const renderTextFromOperation = () => {
		switch (operation) {
			case 2:
				return "Confermi di voler prendere in carico la segnalazione?";
			case 1:
				return "Confermi di voler mettere in attesa la segnalazione?";
			case 3:
				return "Confermi di voler mettere in lavorazione la segnalazione?";
			default:
				return;
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Conferma operazione</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{renderTextFromOperation()}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Annulla
				</Button>
				<Button onClick={handleSave} color="primary" autoFocus>
					Conferma
				</Button>
			</DialogActions>
		</Dialog>
	);
}
