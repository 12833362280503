import React from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(2),
		display: "flex",
		alignItems: "center",
	},
}));

const DateEditor = props => {
	const { value, onValueChange } = props;
	const classes = useStyles();
	return (
		<KeyboardDateTimePicker
			className={classes.container}
			inputVariant="filled"
			variant="inline"
			ampm={false}
			hiddenLabel
			onChange={date => onValueChange(moment(date).toDate())}
			clearable="true"
			onError={console.log}
			format="DD/MM/YYYY HH:mm"
			value={value}
			minDate={moment(value).startOf("hour").toDate()}
			maxDate={moment(value).endOf("day").toDate()}
		/>
	);
};

export default DateEditor;
