const initialState = {};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case "LOGIN": {
			return {
				...state,
				logged: true,
			};
		}
		case "REFRESH": {
			return {
				...state,
			};
		}
		case "PERMISSIONS": {
			return {
				...state,
				permissions: action.permissions,
			};
		}
		case "LOGOUT": {
			return initialState;
		}
		default:
			return state;
	}
};

export default auth;
