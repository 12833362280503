import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container } from "@material-ui/core";
import Datatable from "../../../components/Datatable/";
import { useHistory } from "react-router-dom";
import ViewListIcon from "@material-ui/icons/ViewList";
import { useSelector } from "react-redux";
import {getUtenti} from "../../../services/Helpdesk/Utenti";
import {ButtonGroup} from "@material-ui/core";
import {renderListWithEllipsis} from "../../../utils";

const useStyles = makeStyles(theme => ({
    title: {
        margin: 0,
    },
    tableContainer: {
        padding: "20px 0",
    },
    progressContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));


const userColumns =[
    {
        title: "username",
        field: "username",
        editable: "never",
    },
    {
        title: "email",
        field: "email",
        editable: "never",
    },
    {
        title: "gruppi",
        field: "gruppi",
        editable: "never",
        render: (rowData)=>renderListWithEllipsis(rowData.gruppi,"nome")
    },
    {
        title: "titolo appuntamenti",
        field: "titoloAppuntamenti",
        editable: "never",
    },
    {
        title: "valido",
        field: "isValid",
        editable: "never",
        render: rowData => rowData.isValid?"si":"no"
    },
]

export default function HelpdeskUtenti() {
    const classes = useStyles();
    const history = useHistory();
    const permissions = useSelector(state => state.permissions);
    const [data, setData] = useState();
    useEffect( () => {
        const fetchData = async () => {
            const response = await getUtenti();
            setData(response.data);
        };
        fetchData();
    }, []);

    if (data && permissions) {
        return (
            <Container maxWidth="xl">
                <div className={classes.tableContainer}>
                    <Datatable
                        data={data}
                        columns={userColumns}
                        title="utenti"
                        editable={false}
                        editableAction={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise(async resolve => {
                                    //console.log(oldData, newData);
                                    setTimeout(() => {
                                        resolve();
                                    }, 1000);
                                }),
                        }}
                        actions={[
                            {
                                icon: () => <ViewListIcon />,
                                tooltip: "Dettaglio utente",
                                isFreeAction: false,
                                onClick: (event, rowData) => {
                                    history.push(`/helpdesk/cittadini/${rowData._id}`);
                                },
                            },
                        ]}
                    />
                </div>
            </Container>
        );
    }

    return (
        <Container classes={{ root: classes.progressContainer }} maxWidth="xl">
            <CircularProgress />
        </Container>
    );
}
