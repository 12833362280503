import axios from "axios";

export const getUtenti = async ()=>{
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/utenti`,{
        headers:{
            section: "utenti"
        }
    })
}
export const editUtenti = async (id,payload,section) => {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/utenti/autenticazione?id=${id}`,payload,{
        headers:{
            section: section
        }
    })
}
export const newUtente = async (username) => {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/utenti/autenticazione/${username}`)
}