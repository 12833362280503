import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useForm, Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import {getCittadinoById,editCittadino} from "../../../../services/Helpdesk/Cittadini";
import Button from "@material-ui/core/Button";
import {Check,HowToReg} from "@material-ui/icons";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {Checkbox} from "@material-ui/core";
import FormControlLabel from '@mui/material/FormControlLabel';


const useStyles = makeStyles(theme => ({
    textField:{
        padding:"0px 20px 20px 20px"
    },
    form:{
        marginTop: "10px"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    header__main: {
        margin: "10px 0",
    },
    actionsContainer: {
        display: "flex",
    },
    actionIconsEnabled: {
        padding: "10px",
        borderRadius: "50%",
        background: "#fff",
        marginRight: 10,
        display: "flex",
        boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.15)",
        cursor: "pointer",
    },
    actionIconsDisabled: {
        padding: "10px",
        borderRadius: "50%",
        background: "#fff",
        marginRight: 10,
        display: "flex",
        boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.15)",
        cursor: "pointer",
    },
}));
const textFields = ["_id","username","email","password","sezione"]
const readOnlyFields=["_id","sezione"]
const nestedFields=["notificheConfig","anagrafica"]

export default function DettaglioCittadino() {
    const classes = useStyles();
    let { id } = useParams();
    const [isLoading,setIsLoading]= useState(true);
    const [data,setData]= useState();
    const { register, handleSubmit,  formState: { errors },control,setValue} = useForm();
    const onSubmit = async (data)=>{
        if(!data.password){
            delete data.password;
        }
        try{
            await editCittadino(data._id,data)
        }catch (e){
            //TODO aggiungere popup errore
        }
    }
    const handleUserLock = async ()=>{
        const isValid=!data.isValid
        try{
            await editCittadino(data._id,{isValid})
        }catch (e){
            //TODO aggiungere popup errore
        }
        setData({...data,isValid})
    }
    const handleDeleteUser = ()=>{
        //TODO popup conferma
    }
    useEffect(() => {
        (async () =>
            {
                const response = (await getCittadinoById(id)).data;
                setData(response)
                console.log(response)
                setIsLoading(false)
            }
        )()
    }, [id]);
    if(isLoading){
        return (
            <Container classes={{ root: classes.progress }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <>
            <div className={classes.header}>
                <Link to="/helpdesk" className={classes.backIcon}>
                    <ArrowBackIcon />
                </Link>
                <div className={classes.header__main}>

                </div>
                    <div>
                        <div className={classes.actionsTitle}>
                            <strong>Azioni</strong>
                        </div>
                        <div className={classes.actionsContainer}>
                                <div
                                    className={data.isValid?classes.actionIconsEnabled:classes.actionIconsDisabled}
                                    onClick={handleUserLock}
                                >
                                    <Tooltip title={data.isValid?"BLOCCA":"ATTIVA"} >
                                        {
                                         data.isValid?
                                         <NoAccountsIcon />
                                         : <HowToReg/>
                                        }
                                    </Tooltip>
                                </div>
                            <div
                                className={classes.actionIconsEnabled}
                                onClick={()=>{}}
                            >
                                <Tooltip title={"SALVA"} onClick={handleSubmit(onSubmit)}>
                                            <Check />
                                </Tooltip>
                            </div>
                            <div
                                className={classes.actionIconsEnabled}
                                onClick={()=>{}}
                            >
                                <Tooltip title={"ELIMINA"} onClick={handleDeleteUser}>
                                    <DeleteForeverIcon />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
            </div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth="lg">
            <h5>Campi di registrazione</h5>

                {
                    textFields.map((key,index)=> {
                            return (
                                <span key={"text-"+key} className={classes.textField}>
                                    <Controller
                                        control={control}
                                        name={key}
                                        rules={{ required: false }}
                                        defaultValue={data[key] || ""}
                                        render={props => (
                                            <TextField
                                                label={key}
                                                {...(key == "password" && {type:"password"})}
                                                inputProps={{readOnly: readOnlyFields.includes(key)}}
                                                {...props}
                                                {...(readOnlyFields.includes(key) && {variant:"filled"})}
                                            />
                                        )}
                                    />
                                </span>
                            )
                        }
                    )
                }
                <span className={classes.textField}>
                                <TextField   {...register("lastLogin")}
                                             label="lastLogin" defaultValue={new Moment(data.lastLogin).format("DD/MM/YYYY HH:mm:ss")}
                                             inputProps={{readOnly: true}}
                                             variant="filled"
                                />
                </span>

        </Container>
                <Container maxWidth="lg">
                    <h5>Anagrafica</h5>
                    {!data.anagrafica ?
                        "anagrafica non presente":
                        Object.keys(data.anagrafica).filter(key=> key !== "_id" ).map( (key)=> {
                                return (
                                    <span key={"text-anagrafica-" + key} className={classes.textField}>
                                    {
                                        key.search(/data/i) === -1 ?
                                            <Controller
                                                control={control}
                                                name={"anagrafica." + key}
                                                rules={{ required: false }}
                                                defaultValue={data.anagrafica[key]}
                                                render={props => (
                                                <TextField
                                                             label={key} {...props}
                                                />
                                                    )}
                                                />

                                            :
                                            <Controller
                                                control={control}
                                                name={"anagrafica." + key}
                                                rules={{ required: false }}
                                                defaultValue={data.anagrafica[key]}
                                                render={props => (
                                                        <>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    label={key}
                                                                    disableFuture={true}
                                                                    inputFormat="MM/dd/yyyy"
                                                                    {...props}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </>
                                                    )}
                                            />

                                    }
                                </span>
                                )
                            }
                        )
                    }
                </Container>
            <Container maxWidth="lg">
                <h5>Configurazione Notifiche</h5>
                {
                    Object.keys(data.notificheConfig).filter(key=> key !== "_id" ).map( (key)=> {
                            return (
                                <span key={"text-notifiche-" + key} className={classes.textField}>
                                            <Controller
                                                control={control}
                                                name={"notificheConfig." + key}
                                                rules={{ required: false }}
                                                defaultValue={data.notificheConfig[key] === true}
                                                render={props => (
                                                    <FormControlLabel control={
                                                        <Checkbox
                                                            {...props}
                                                            onChange={(e) => props.onChange(e.target.checked)}
                                                            checked={props.value}
                                                            color="primary"
                                                        />
                                                    } label={key}/>
                                                    )}
                                            />
                                </span>
                            )
                        }
                    )
                }
            </Container>
            </form>
        </>
    );
}
