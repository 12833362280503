import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { login, refresh } from "../services/Auth";
import {
	getSession,
	removeSession,
	saveSession,
} from "react-session-persist/lib";
import { jwt_decode } from "../utils";
import { useToast } from "./Toast";
import moment from "moment";

const AuthContext = React.createContext();

async function initializeResponseInterceptor(history, fireToast) {
	axios.interceptors.response.use(
		response => response,
		async error => {
			const session = getSession();
			let originalRequest = error.config;

			if (
				originalRequest.url ===
					`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/refreshToken` ||
				jwt_decode(session.refreshToken).exp - moment().unix() <= 0
			) {
				fireToast(
					"La tua session è scaduta. Si prega di rieseguire l'accesso.",
					null,
					"warning"
				);
				await removeSession();
				history.push("/", {
					reason: "catch in refresh function",
				});
				return Promise.reject(error);
			}

			if (error && error.response && error.response.status !== 401) {
				return Promise.reject(error);
			}

			if (!session) {
				history.push("/", {
					reason: "refresh expired",
				});
				return Promise.reject(error);
			}

			if (!originalRequest._retry) {
				originalRequest._retry = true;
				try {
					const {
						data: { token },
					} = await refresh();
					if (!token) {
						console.log("No token found!", token);
						return Promise.reject(error);
					}
					axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
					originalRequest.headers["Authorization"] = `Bearer ${token}`;
					await saveSession({
						token: token,
						refreshToken: session.refreshToken,
						...jwt_decode(token),
					});
					return axios(originalRequest);
				} catch (err) {
					await removeSession();
					history.push("/", {
						reason: "catch in refresh function",
					});
					return Promise.reject(err);
				}
			} else {
				await removeSession();
				history.push("/", {
					reason: "Can't retry",
				});
				return Promise.reject(error);
			}
		}
	);
}

function AuthProvider(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const session = getSession();
	const { fireToast } = useToast();

	initializeResponseInterceptor(history, fireToast);

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (session && session.refreshToken) {
				if (
					jwt_decode(session.refreshToken).exp - moment().unix() < 60 * 60 &&
					jwt_decode(session.refreshToken).exp - moment().unix() > 0
				) {
					fireToast(
						"La tua sessione scadrà tra meno di un'ora. Ti consigliamo ti concludere le operazioni e di rieseguire il login.",
						null,
						"warning"
					);
					clearInterval(interval);
				}
			}
		}, 5000);
	}, [session, fireToast]);

	const value = React.useMemo(
		() => ({
			login: async (username, password) => {
				try {
					const { data } = await login(username, password);
					await saveSession({
						token: data.token,
						refreshToken: data.refreshToken,
						...jwt_decode(data.token),
					});
					axios.defaults.headers.common[
						"Authorization"
					] = `Bearer ${data.token}`;
					dispatch({ type: "LOGIN" });
				} catch (err) {
					return Promise.reject(err);
				}
			},
			logout: async () => {
				await removeSession();
				dispatch({ type: "LOGOUT" });
			},
			getAuthState: () => getSession(),
		}),
		[dispatch]
	);
	return <AuthContext.Provider {...props} value={value} />;
}

function useAuth() {
	const context = React.useContext(AuthContext);

	if (!context) {
		throw new Error(`AuthContext must be used within a AuthProvider`);
	}

	const { login, logout, getAuthState } = context;

	return {
		login,
		logout,
		getAuthState,
	};
}

export { AuthProvider, useAuth };
