import axios from "axios";
import { getSession } from "react-session-persist";

export const uploadAttachment = async (formData, category) => {
  const session = getSession();
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/news/allegati/${category}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${session.token}`,
      },
    }
  );
};

export const getAttachment = async id => {
  const session = getSession();
  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/news/allegati?id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
      responseType: "blob",
    }
  );
};
