import { Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

const ToastContext = React.createContext();

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ToastProvider({ children, ...props }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");
	const [autoHideDuration, setAutoHideDuration] = React.useState(4000);
	const value = React.useMemo(
		() => ({
			fireToast: (message, autoHide = 4000, severity = "success") => {
				setMessage(message);
				setSeverity(severity);
				setAutoHideDuration(autoHide);
				setOpen(true);
				setTimeout(() => setOpen(false), 3000);
			},
		}),
		[]
	);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	return (
		<ToastContext.Provider value={value} {...props}>
			{children}
			<div className={classes.root}>
				<Snackbar
					open={open}
					autoHideDuration={autoHideDuration}
					onClose={handleClose}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				>
					<Alert onClose={handleClose} severity={severity}>
						{message}
					</Alert>
				</Snackbar>
			</div>
		</ToastContext.Provider>
	);
}

function useToast() {
	const context = React.useContext(ToastContext);

	if (!context) {
		throw new Error(`useToast must be used within a ToastProvider`);
	}

	const { fireToast } = context;

	return {
		fireToast,
	};
}

export { ToastProvider, useToast };
