import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
	getTicket,
	getAttachment,
	updateStatus,
} from "../../../services/Segnalazioni";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getStateFromBaseValue } from "../../../utils";
import { AssignmentInd, Pause, Cached, Done } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Carousel from "../../../components/Carousel";
import ConfirmDialog from "./ConfirmDialog";
import ClosureDialog from "./ClosureDialog";
import AssignmentDialog from "./AssignmentDialog";
import Map from "../../../components/Map";

const useStyles = makeStyles(theme => ({
	progress: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
	},
	header__main: {
		margin: "10px 0",
	},
	actionsContainer: {
		display: "flex",
	},
	actionIcons: {
		padding: "10px",
		borderRadius: "50%",
		background: "#fff",
		marginRight: 10,
		display: "flex",
		boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.15)",
		cursor: "pointer",
	},
	hover: {
		background: "#fff",
		"&:hover": {
			background: "#ccc",
		},
	},
	actionsTitle: {
		marginBottom: 5,
	},
	category: {
		marginLeft: "10px",
		padding: "5px",
		color: "#fff",
		backgroundColor: "orange",
		borderRadius: "5px",
		width: "contain",
	},
	subCategory: {
		marginLeft: "10px",
		padding: "5px",
		color: "#fff",
		backgroundColor: "green",
		borderRadius: "5px",
		width: "contain",
	},
	title: {
		display: "flex",
		alignItems: "center",
		"& strong": {
			fontSize: "1.8rem",
		},
	},
	carousel: {
		width: "100%",
		display: "flex",
	},
	image: {
		height: "400px",
		width: "400px",
		backgroundColor: "#ccc",
		margin: "10px 10px",
	},
	state: {
		padding: "10px 0",
		fontSize: "15px",
	},
	disabled: {
		background: "#999",
		pointerEvents: "none",
		opacity: 0.5,
	},
	backIcon: {
		textDecoration: "none",
		color: theme.palette.grey[800],
	},
	dialogContainer: {
		display: "flex",
		flexDirection: "column",
	},
	formControl: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		minWidth: 300,
	},
	selectTitle: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	textArea: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
		minWidth: 500,
	},
	descriptionContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	confirmDialog: {
		padding: 0,
	},
	map: {},
}));

export default function Dettaglio() {
	const [ticket, setTicket] = useState();
	const [attachments, setAttachments] = useState();
	const [state, setState] = useState();
	const [currentState, setCurrentState] = useState();
	const [open, setOpen] = useState(false);
	const [action, setAction] = useState();
	const [closureState, setClosureState] = useState(4);
	const [note, setNote] = useState("");
	const permissions = useSelector(state => state.permissions);
	const classes = useStyles();
	let { id } = useParams();

	useEffect(() => {
		const getTicketById = async () => {
			const { data } = await getTicket(id);
			const attachments = await Promise.all(
				data.allegati.map(attachment =>
					getAttachment(attachment, data.categoria)
				)
			);
			setTicket(data);
			setState(data.stato);
			setAttachments(
				attachments.map(img => {
					const obj = {
						id: Math.random().toString(36),
						url: URL.createObjectURL(img.data),
					};
					return obj;
				})
			);
		};
		getTicketById();
	}, [id]);

	const aggiornaStato = async newState => {
		const { data } = await updateStatus(id, newState, note, ticket.categoria);
		if (data) {
			setState(data.stato);
			setTicket(data);
		}
	};
	const handleCloseDialog = () => setOpen(false);
	const handleOpenDialog = () => {
		setAction("closure");
		setOpen(true);
	};
	const handleOpenConfirm = () => {
		setAction("confirm");
		setOpen(true);
	};
	const handleOpenAssignment = () => {
		setAction("assignment");
		setOpen(true);
	};
	const handleChangeClosureState = event => setClosureState(event.target.value);
	const handleChangeNote = event => setNote(event.target.value);

	const hasPermissions = () => {
		if (ticket && permissions) {
			const currentPermission = permissions.find(
				permission => permission.sezione === "segnalazioni"
			);
			for (let key in currentPermission) {
				if (currentPermission.hasOwnProperty(key)) {
					if (key === ticket.categoria) {
						return currentPermission[key] < 3;
					}
				}
			}
		}
		return false;
	};

	const hasHighPermission = () => {
		if (ticket && permissions) {
			const currentPermission = permissions.find(
				permission => permission.sezione === "segnalazioni"
			);
			if (currentPermission.segnalazioni === 0) return true;
			for (let key in currentPermission) {
				if (currentPermission.hasOwnProperty(key)) {
					if (key === ticket.categoria) {
						return currentPermission[key] === 0;
					}
				}
			}
		}
	};

	const handleSaveOperation = async () => {
		await aggiornaStato(currentState);
		setOpen(false);
	};
	const handleSave = async () => {
		await aggiornaStato(closureState);
		setOpen(false);
	};

	if (ticket && attachments && permissions) {
		const {
			categoria,
			sottoCategoria,
			descrizione,
			counter,
			autore,
			posizione,
			operatore,
			risposta,
		} = ticket;

		return (
			<Container maxWidth="xl">
				<Link to="/segnalazioni" className={classes.backIcon}>
					<ArrowBackIcon />
				</Link>
				<div className={classes.header}>
					<div className={classes.header__main}>
						<strong>#{counter}</strong>
						<div className={classes.title}>
							<div className={classes.category}>{categoria}</div>
							<div className={classes.subCategory}>{sottoCategoria}</div>
						</div>
						<div className={classes.state}>
							<span>
								<strong>STATO:</strong> {getStateFromBaseValue(state)}
							</span>
						</div>
					</div>
					{permissions.find(permission => permission.sezione === "segnalazioni")
						.segnalazioni < 3 || hasPermissions() ? (
						<div>
							<div className={classes.actionsTitle}>
								<strong>Azioni</strong>
							</div>
							<div className={classes.actionsContainer}>
								{hasHighPermission() ? (
									<div
										className={classes.actionIcons}
										style={
											!hasHighPermission() &&
											(getStateFromBaseValue(state) === "IN ATTESA" ||
												getStateFromBaseValue(state) === "PRESA IN CARICO" ||
												getStateFromBaseValue(state) === "IN LAVORAZIONE" ||
												state >= 4)
												? {
														background: "#999",
														pointerEvents: "none",
														opacity: 0.5,
												  }
												: {
														background: "#fff",
												  }
										}
										onClick={handleOpenAssignment}
									>
										<Tooltip title="ASSEGNA" onClick={() => setCurrentState(2)}>
											<AssignmentInd />
										</Tooltip>
									</div>
								) : null}

								<div
									className={classes.actionIcons}
									style={
										getStateFromBaseValue(state) === "IN ATTESA" ||
										getStateFromBaseValue(state) === "PRESA IN CARICO" ||
										getStateFromBaseValue(state) === "IN LAVORAZIONE" ||
										state >= 4
											? {
													background: "#999",
													pointerEvents: "none",
													opacity: 0.5,
											  }
											: {
													background: "#fff",
											  }
									}
									onClick={handleOpenConfirm}
								>
									<Tooltip
										title="PRENDI IN CARICO"
										onClick={() => setCurrentState(2)}
									>
										<AssignmentTurnedInIcon />
									</Tooltip>
								</div>

								<div
									className={classes.actionIcons}
									style={
										getStateFromBaseValue(state) === "IN ATTESA" || state >= 2
											? {
													background: "#999",
													pointerEvents: "none",
													opacity: 0.5,
											  }
											: {
													background: "#fff",
											  }
									}
									onClick={handleOpenConfirm}
								>
									<Tooltip
										title="METTI IN ATTESA"
										onClick={() => setCurrentState(1)}
									>
										<Pause />
									</Tooltip>
								</div>
								<div
									className={classes.actionIcons}
									style={
										getStateFromBaseValue(state) === "IN LAVORAZIONE" ||
										state >= 4
											? {
													background: "#999",
													pointerEvents: "none",
													opacity: 0.5,
											  }
											: {
													background: "#fff",
											  }
									}
									onClick={handleOpenConfirm}
								>
									<Tooltip
										title="IN LAVORAZIONE"
										onClick={() => setCurrentState(3)}
									>
										<Cached />
									</Tooltip>
								</div>
								<div
									className={classes.actionIcons}
									style={
										state >= 4
											? {
													background: "#999",
													pointerEvents: "none",
													opacity: 0.5,
											  }
											: {
													background: "#fff",
											  }
									}
									onClick={handleOpenDialog}
								>
									<Tooltip title="CHIUDI">
										<Done />
									</Tooltip>
								</div>
							</div>
						</div>
					) : null}
				</div>
				<div className={classes.descriptionContainer}>
					<h2>Segnalato da: </h2>
					<span>
						{autore?.username}{" "}
						{permissions.find(
							permission => permission.sezione === "segnalazioni"
						).segnalazioni === 0 ? (
							<a href={`mailto:${autore?.email}`}>({autore?.email})</a>
						) : (
							""
						)}
					</span>

					{state >= 2 ? (
						<div>
							<h2>Assegnato a : </h2>
							<span>
								{operatore?.username}{" "}
								{permissions.find(
									permission => permission.sezione === "segnalazioni"
								).segnalazioni === 0 ? (
									<a href={`mailto:${operatore?.email}`}>
										({operatore?.email})
									</a>
								) : (
									""
								)}
							</span>
						</div>
					) : (
						""
					)}

					{state >= 4 ? (
						<div>
							<h2>Nota di risposta : </h2>
							<span>
								{risposta ? risposta : "Nessuna nota di risposta specificata"}
							</span>
						</div>
					) : (
						""
					)}
					<h2>Descrizione</h2>
					<span>
						{descrizione ? descrizione : "Nessuna descrizione inserita."}
					</span>
				</div>
				<div className={classes.carousel}>
					{attachments && attachments.length ? (
						<Carousel data={attachments} />
					) : (
						<strong style={{ marginTop: 20, marginBottom: 20 }}>
							Nessuna foto allegata.
						</strong>
					)}
				</div>
				<div className={classes.Map}>
					<Map marker={true} lat={posizione[0]} lng={posizione[1]}></Map>
				</div>
				<ClosureDialog
					open={open && action === "closure"}
					classes={classes}
					closureState={closureState}
					handleChangeClosureState={handleChangeClosureState}
					handleChangeNote={handleChangeNote}
					handleCloseDialog={handleCloseDialog}
					handleSave={handleSave}
					note={note}
				/>
				<ConfirmDialog
					open={open && action === "confirm"}
					handleClose={handleCloseDialog}
					handleSave={handleSaveOperation}
					operation={currentState}
				/>
				<AssignmentDialog
					open={open && action === "assignment"}
					category={ticket.categoria}
					section="segnalazioni"
					ticketId={ticket._id}
					handleClose={handleCloseDialog}
					onSave={data => {
						setTicket(data);
						setState(data.stato);
						setOpen(false);
					}}
				/>
			</Container>
		);
	}

	return (
		<Container classes={{ root: classes.progress }}>
			<CircularProgress />
		</Container>
	);
}
