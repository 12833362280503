import React from "react";
import Box from "../../components/Box";
import Scheduler from "../../components/Scheduler";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Controller, useForm } from "react-hook-form";
import { blue, purple, red } from "@material-ui/core/colors";
import { TimePicker } from "@material-ui/pickers";
import CustomizedDialogs from "../../components/Dialog";
import moment from "moment";
import "moment/locale/it";
import {
	Backdrop,
	CardActions,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	addUserDisponibility,
	batch,
	editUserDisponibility,
	getAppointments,
	getAllAppointments,
	getMembers,
	getUserDisponibility,
} from "../../services/Appuntamenti";
import { isEmpty } from "../../utils";
import { getSession, saveSession } from "react-session-persist/lib";
import { editUserInfo } from "../../services/User";
import { useSelector } from "react-redux";

moment().locale("it");

const useStyles = makeStyles(theme => ({
	bottomSection: {
		display: "flex",
		justifyContent: "space-between",
		marginTop: theme.spacing(1),
	},
	scheduler__container: {
		width: "80%",
	},
	scheduler: {
		height: "100%",
	},
	sidebar: {
		display: "flex",
		width: "20%",
		flexDirection: "column",
		marginLeft: theme.spacing(1),
	},
	hours__container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	undoButton: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(5),
		zIndex: 1,
	},
	saveButton: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		zIndex: 1,
	},
	formControl: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		minWidth: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	date__container: {
		display: "flex",
		justifyContent: "space-between",
	},
	date: {
		margin: 10,
	},
	form: {
		width: 400,
		margin: "0 auto",
		"& small[class=error]": {
			color: red[600],
			margin: "10px 0",
		},
	},
	add_hour_button__container: {
		display: "flex",
		justifyContent: "center",
		marginTop: theme.spacing(2),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const BlueButton = withStyles(theme => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: blue[700],
		"&:hover": {
			backgroundColor: blue[900],
		},
	},
}))(Button);

const PurpleButton = withStyles(theme => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: purple[700],
		"&:hover": {
			backgroundColor: purple[900],
		},
	},
}))(Button);

const RedButton = withStyles(theme => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: red[700],
		"&:hover": {
			backgroundColor: red[900],
		},
	},
}))(Button);

export default function Appuntamenti() {
	const classes = useStyles();
	const permissions = useSelector(state => state.permissions);
	const { handleSubmit, register, errors, control, watch } = useForm();
	const watchAllFields = watch();
	const session = getSession();
	const schedulerRef = React.createRef();
	const [ titoloAppuntamenti,setTitoloAppuntamenti ]  = React.useState(session?.titoloAppuntamenti);
	const [hours, setHours] = React.useState([]);
	const [occupiedDays, setOccupiedDays] = React.useState([]);
	const [selectedDay, setSelectedDay] = React.useState();
	const [appointments, setAppointments] = React.useState([]);
	const [oldData, setOldData] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [titleDialogOpen, setTitleDialogOpen] = React.useState(false);
	const [setupDialogOpen, setSetupDialogOpen] = React.useState(false);
	const [changedAppointments, setChangedAppointments] = React.useState({});
	const [addedAppointments, setAddedAppointments] = React.useState({});
	const [deletedAppointments, setDeletedAppointments] = React.useState({});
	const [localPermissions, setLocalPermissions] = React.useState({
		canEdit: false,
		canDelete: false,
	});
	const [
		disponibilityPermissions,
		setDisponibilityPermissions,
	] = React.useState({
		canEdit: false,
	});
	const [currentDate, setCurrentDate] = React.useState(
		moment().format("YYYY-MM-DD")
	);
	const [filter, setFilter] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [disabled, setDisabled] = React.useState(true);
	const [resources, setResources] = React.useState([
		{
			fieldName: "members",
			title: " ",
			allowMultiple: true,
			instances: [
				{
					id: session._id,
					text: session.username,
					color: blue[600],
				},
			],
		},
	]);

	React.useEffect(() => {
		const currentPermissions = permissions.find(
			permission => permission.sezione === "appuntamenti"
		);
		switch (currentPermissions.scheduler) {
			case 0:
			case 1: {
				setLocalPermissions({
					canEdit: true,
					canDelete: true,
				});
				break;
			}
			case 2: {
				setLocalPermissions({
					canEdit: true,
					canDelete: false,
				});
				break;
			}
			case 3: {
				setLocalPermissions({
					canEdit: false,
					canDelete: false,
				});
				break;
			}
			default:
				return;
		}
		switch (currentPermissions.disponibilita) {
			case 0:
			case 1:
			case 2: {
				setDisponibilityPermissions({ canEdit: true });
				break;
			}
			default:
				return;
		}
	}, [permissions]);

	React.useEffect(() => {
		const currentPermissions = permissions.find(
			permission => permission.sezione === "appuntamenti"
		);
		const requests = [];
		setLoading(true);
		if (
			currentPermissions.appuntamenti &&
			currentPermissions.appuntamenti <= 3
		) {
			requests.push(getAllAppointments());
			requests.push(getMembers());
		} else {
			requests.push(getAppointments());
		}
		requests.push(getUserDisponibility());
		(async () => {
			try {
				const response = await Promise.all(requests);
				if (requests.length > 2) {
					prepareData(response[0], response[2], response[1]);
				} else {
					prepareData(response[0], response[1]);
				}
				setLoading(false);
			} catch (err) {
				console.error("err", err);
				setLoading(false);
			}
		})();
	}, [permissions]);

	React.useEffect(() => {
		const currentPermissions = permissions.find(
			permission => permission.sezione === "appuntamenti"
		);
		setLoading(true);
		(async () => {
			try {
				let response = [];
				if (
					currentPermissions.appuntamenti &&
					currentPermissions.appuntamenti <= 3
				) {
					response = await getAllAppointments(
						moment(currentDate).startOf("week").toISOString(),
						moment(currentDate).endOf("week").toISOString()
					);
				} else {
					response = await getAppointments(
						moment(currentDate).startOf("week").toISOString(),
						moment(currentDate).endOf("week").toISOString()
					);
				}
				const data = response.data.map(appointment => {
					return {
						...appointment,
						startDate: moment(appointment.startDate).toDate(),
						endDate: moment(appointment.endDate).toDate(),
						categoria: appointment.categoria || "",
						id: appointment._id,
						editable: true,
					};
				});
				setAppointments(data);
				setOldData(data);
				setLoading(false);
			} catch (err) {
				console.error("err", err);
				setLoading(false);
			}
		})();
	}, [permissions, currentDate]);

	React.useEffect(() => {
		if (hours.length) {
			for (let obj of hours) {
				if (!occupiedDays.includes(obj.day)) {
					setOccupiedDays(prev => [...prev, obj.day]);
				}
			}
		}
	}, [hours, occupiedDays]);

	React.useEffect(() => {
		if (!titoloAppuntamenti) setTitleDialogOpen(true);
	}, [titoloAppuntamenti]);

	const prepareData = (appointments, disponibility, members = null) => {
		const currentAppointments = appointments.data.map(appointment => {
			return {
				...appointment,
				startDate: moment(appointment.startDate).toDate(),
				endDate: moment(appointment.endDate).toDate(),
				categoria: appointment.categoria || "",
				id: appointment._id,
				editable: true,
			};
		});

		const currentHours = disponibility.data
			.map(disponibility => {
				return {
					day: disponibility.giornoSettimana,
					hours: disponibility.intervalli.map(hour => {
						return {
							startDate: hour.dataInizio,
							endDate: hour.dataFine,
						};
					}),
				};
			})
			.filter(day => day.hours.length)
			.sort((a, b) => a.day - b.day);

		if (members) {
			const currentMembers = members.data.map(member => {
				return {
					id: member._id,
					text: member.username,
					title: " ",
					email: member.email,
				};
			});
			setResources(prev => [{ ...prev[0], instances: currentMembers }]);
		}

		setAppointments(currentAppointments);
		setOldData(currentAppointments);
		setHours(currentHours);
	};

	const addDisponibility = data => {
		setHours([...hours, data].filter(el => el.day !== ""));
		setOpen(false);
		(async () => {
			try {
				const payload = {
					giornoSettimana: data.day,
					intervalli: data.hours.map(hour => {
						return {
							dataInizio: moment(hour.startDate).toISOString(),
							dataFine: moment(hour.endDate).toISOString(),
						};
					}),
				};
				await addUserDisponibility(payload);
			} catch (err) {
				console.error("error", err);
			}
		})();
	};

	const removeDisponibility = day => {
		setHours(hours.filter(el => el.day !== day));
		setOccupiedDays(prev => prev.filter(occupiedDay => occupiedDay !== day));
		(async () => {
			const payload = {
				giornoSettimana: day,
				intervalli: [],
			};
			try {
				await editUserDisponibility(payload);
			} catch (err) {
				console.error("error", err);
			}
		})();
	};

	const prepareHours = () => {
		let genericDay = {
			day: "",
			hours: [{ startDate: null, endDate: null }],
		};
		setSelectedDay(genericDay);
		setHours([...hours, genericDay]);
		setOpen(true);
	};

	const handleSchedulerChange = ({ added, changed, deleted }) => {
		setDisabled(false);
		if (added) {
			console.log("added", added);
			const key = Object.keys(added)[0];
			let update = addedAppointments;
			update[key] = { ...added[key] };
			setAddedAppointments(update);
		}
		if (changed) {
			const key = Object.keys(changed)[0];
			if (addedAppointments.hasOwnProperty(key)) {
				console.log("added appointments", addedAppointments);
				let update = addedAppointments;
				update[key] = { ...addedAppointments[key], ...changed[key] };
				setAddedAppointments(update);
			} else {
				let update = changedAppointments;
				update[key] = { ...changed[key] };
				setChangedAppointments(update);
			}
		}
		if (deleted) {
			let update = deletedAppointments;
			update[deleted] = deleted;
			if (addedAppointments.hasOwnProperty(deleted)) {
				let update = addedAppointments;
				update[deleted] = undefined;
				setAddedAppointments(update);
			}
			setDeletedAppointments(update);
		}
	};

	const handleUndo = () => {
		setAppointments(oldData);
		setChangedAppointments({});
		setAddedAppointments({});
		setDeletedAppointments({});
		setDisabled(true);
	};

	const handleConfirm = () => {
		const changedPayload = [];
		const addedPayload = [];
		const deletedPayload = [];
		const batchArray = [];

		if (Object.keys(changedAppointments).length) {
			for (let key in changedAppointments) {
				if (changedAppointments[key])
					changedPayload.push({
						id: key,
						...changedAppointments[key],
					});
			}

			console.log("changedPayload", changedPayload);

			batchArray.push(batch(changedPayload, "PUT"));
		}

		if (Object.keys(addedAppointments).length) {
			for (let key in addedAppointments) {
				if (addedAppointments[key])
					addedPayload.push({
						...addedAppointments[key],
					});
			}

			console.log("addedPayload", addedPayload);

			batchArray.push(batch(addedPayload, "POST"));
		}

		if (Object.keys(deletedAppointments).length) {
			for (let key in deletedAppointments) {
				if (deletedAppointments[key]) {
					const update = deletedAppointments;
					deletedPayload.push(key);
					update[key] = null;
					setDeletedAppointments(update);
				}
			}

			console.log("deletedPayload", deletedPayload);

			batchArray.push(batch(deletedPayload, "DELETE"));
		}

		setChangedAppointments({});
		setAddedAppointments({});
		setDeletedAppointments({});

		(async () => {
			try {
				await Promise.all(batchArray);
				const response = await getAppointments(
					moment(currentDate).startOf("week").toISOString(),
					moment(currentDate).endOf("week").toISOString()
				);
				const updatedData = response.data.map(appointment => {
					return {
						...appointment,
						id: appointment._id,
						startDate: moment(appointment.startDate).toDate(),
						endDate: moment(appointment.endDate).toDate(),
						categoria: appointment.categoria || "",
						editable: true,
					};
				});
				setAppointments(updatedData);
				setOldData(updatedData);
				setDisabled(true);
			} catch (err) {
				console.error("error", err);
				setDisabled(true);
			}
		})();
	};

	const handleTitleSubmit = async data => {
		try {
			await editUserInfo(data);
			saveSession({ ...session, titoloAppuntamenti: data.titoloAppuntamenti });
			setTitoloAppuntamenti(data.titoloAppuntamenti);
			setTitleDialogOpen(false);
		} catch (err) {
			console.error("error", err);
			setTitleDialogOpen(false);
		}
	};
	const handleTitleEditSubmit = async data => {
		try {
			await editUserInfo({titoloAppuntamenti:data.titoloEditAppuntamenti});
			saveSession({ ...session, titoloAppuntamenti: data.titoloEditAppuntamenti });
			setTitoloAppuntamenti(data.titoloEditAppuntamenti)
			setSetupDialogOpen(false);
		} catch (err) {
			console.error("error", err);
			setSetupDialogOpen(false);
		}
	};
	const handleAppointmentsUpdate = data => {
		setAppointments(data);
	};

	const handleDateChange = date => setCurrentDate(date);

	return (
		<>
			<div className={classes.bottomSection}>
				<Box
					padding="0px"
					internalTitle="Calendario appuntamenti"
					className={classes.scheduler__container}
				>
					<Scheduler
						ref={schedulerRef}
						className={classes.scheduler}
						schedulerData={appointments}
						currentDate={currentDate}
						onChange={handleSchedulerChange}
						onDateChange={handleDateChange}
						onUpdate={handleAppointmentsUpdate}
						canEdit={localPermissions.canEdit}
						canDelete={localPermissions.canDelete}
						filter={filter}
						resources={resources}
					/>
				</Box>
				<div className={classes.sidebar}>
					{localPermissions.canEdit && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: 10,
							}}
						>
							<RedButton
								variant="contained"
								disabled={disabled}
								onClick={handleUndo}
							>
								Annulla modifiche
							</RedButton>
							<PurpleButton
								variant="contained"
								disabled={disabled}
								onClick={handleConfirm}
							>
								Salva modifiche
							</PurpleButton>
						</div>
					)}
					<Box padding={15} internalTitle="Disponibilità">
						<div className={classes.hours__container}>
							{hours
								.filter(el => el.day !== "")
								.map((el, index) => (
									<HourCard
										data={el}
										onDelete={removeDisponibility}
										canEdit={disponibilityPermissions.canEdit}
										key={index}
									/>
								))}
							{disponibilityPermissions.canEdit && (
								<BlueButton
									style={{ marginTop: "10px" }}
									variant="contained"
									onClick={prepareHours}
								>
									Aggiungi nuovo orario
								</BlueButton>
							)}
						</div>
					</Box>
					<RedButton
						variant="contained"
						title="Gestisci"
						style={{ marginTop: 10 }}
						onClick={() => setSetupDialogOpen(true)}
					>
						Gestisci titolo
					</RedButton>
					{permissions.find(permission => permission.sezione === "appuntamenti")
						.appuntamenti && (
						<Box padding={15} internalTitle="Filtro per utente" spaced>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormGroup>
									{resources
										.find(resource => resource.fieldName === "members")
										.instances.map(instance => (
											<FormControlLabel
												key={instance.id}
												control={
													<Checkbox
														checked={!filter.includes(instance.id)}
														name={instance.text}
													/>
												}
												label={instance.text}
												onClick={() => {
													if (filter.includes(instance.id)) {
														setFilter(
															filter.filter(value => value !== instance.id)
														);
													} else {
														setFilter([...filter, instance.id]);
													}
												}}
											/>
										))}
								</FormGroup>
							</FormControl>
						</Box>
					)}
				</div>
			</div>
			<CustomizedDialogs
				title="Aggiunta nuovo orario di disponibilità"
				onClose={() => setOpen(false)}
				onSave={handleSubmit(addDisponibility)}
				open={open}
				buttonTitle="Aggiungi disponibilità"
			>
				{selectedDay && (
					<form className={classes.form}>
						<small>
							<strong>Seleziona il giorno</strong>
						</small>
						<Controller
							name="day"
							control={control}
							rules={{
								required: {
									value: true,
									message: "Il giorno è obbligatorio.",
								},
								validate: value =>
									!occupiedDays.includes(value) ||
									"Questo giorno è già stato reso disponibile.",
							}}
							defaultValue={1}
							render={props => (
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel id="day-label">Giorno</InputLabel>
									<Select
										labelId="day-label"
										id="day-select"
										error={errors.giorno !== undefined}
										label="Giorno"
										{...props}
									>
										<MenuItem value={1}>Lunedì</MenuItem>
										<MenuItem value={2}>Martedì</MenuItem>
										<MenuItem value={3}>Mercoledì</MenuItem>
										<MenuItem value={4}>Giovedì</MenuItem>
										<MenuItem value={5}>Venerdì</MenuItem>
									</Select>
								</FormControl>
							)}
						/>
						{errors?.day && (
							<small className="error">{errors.day?.message}</small>
						)}
						<div>
							<small>
								<strong>Seleziona gli orari di inizio e fine</strong>
							</small>
						</div>
						{selectedDay.hours.map((day, index) => (
							<div key={index} className={classes.date__container}>
								<div>
									<Controller
										name={`hours[${index}].startDate`}
										control={control}
										defaultValue={moment().startOf("hour")}
										rules={{
											required: {
												value: true,
												message: "Questo campo è obbligatorio.",
											},
											validate: value =>
												(!isEmpty(watchAllFields) &&
													moment(value).isBefore(
														moment(watchAllFields?.hours[index]?.endDate)
													)) ||
												"L'orario d'inizio non è valido.",
										}}
										render={props => (
											<TimePicker
												{...props}
												label="Inizio"
												showTodayButton
												ampm={false}
												className={classes.date}
												todayLabel="ora"
												minutesStep={15}
												error={
													errors.hours &&
													errors?.hours[index]?.startDate !== undefined
												}
											/>
										)}
									/>
									{errors.hours && errors.hours[index]?.startDate && (
										<small className="error">
											{errors.hours[index].startDate?.message}
										</small>
									)}
								</div>
								<div>
									<Controller
										name={`hours[${index}].endDate`}
										control={control}
										rules={{
											required: {
												value: true,
												message: "Questo campo è obbligatorio.",
											},
											validate: value =>
												(!isEmpty(watchAllFields) &&
													moment(value).isAfter(
														moment(watchAllFields?.hours[index]?.startDate)
													)) ||
												"L'orario di fine non è valido.",
										}}
										defaultValue={moment().startOf("hour").add(1, "hour")}
										render={props => (
											<TimePicker
												{...props}
												label="Fine"
												showTodayButton
												ampm={false}
												className={classes.date}
												todayLabel="ora"
												minutesStep={15}
												error={
													errors.hours &&
													errors?.hours[index]?.endDate !== undefined
												}
											/>
										)}
									/>
									{errors.hours && errors.hours[index]?.endDate && (
										<small className="error">
											{errors.hours[index].endDate?.message}
										</small>
									)}
								</div>
							</div>
						))}
						{selectedDay.hours.length < 2 ? (
							<div className={classes.add_hour_button__container}>
								<RedButton
									variant="contained"
									onClick={() => {
										setSelectedDay(prev => {
											return {
												...prev,
												hours: [
													...prev.hours,
													{
														startDate: moment(),
														endDate: moment().add(1, "hour"),
													},
												],
											};
										});
									}}
								>
									Aggiungi altro orario
								</RedButton>
							</div>
						) : null}
					</form>
				)}
			</CustomizedDialogs>
			<CustomizedDialogs
				open={titleDialogOpen}
				title="Titolo per appuntamenti"
				buttonTitle="Aggiungi titolo"
				onSave={handleSubmit(handleTitleSubmit)}
			>
				<form className={classes.form}>
					<span>
						Si prega di inserire il titolo con cui presentarsi al cittadino (ad
						esempio, Mario Rossi - Sindaco)
					</span>
					<TextField
						name="titoloAppuntamenti"
						placeholder="Titolo per appuntamenti"
						style={{ marginTop: 10 }}
						fullWidth
						variant="outlined"
						error={errors.titoloAppuntamenti !== undefined}
						inputRef={register({
							required: { value: true, message: "Il titolo è obbligatorio." },
						})}
					/>
					{errors?.titoloAppuntamenti && (
						<small className="error">{errors.titoloAppuntamenti.message}</small>
					)}
				</form>
			</CustomizedDialogs>
			<CustomizedDialogs
				open={setupDialogOpen}
				title="Gestione titolo"
				buttonTitle="Conferma"
				onSave={handleSubmit(handleTitleEditSubmit)}
			>
				<form className={classes.form}>
					<span>Il titolo che apparirà ai cittadini sull'App.</span>
					<TextField
						name="titoloEditAppuntamenti"
						placeholder="Titolo per appuntamenti"
						style={{ marginTop: 10 }}
						fullWidth
						variant="outlined"
						error={errors.titoloEditAppuntamenti !== undefined}
						inputRef={register({
							required: { value: true, message: "Il titolo è obbligatorio." },
						})}
						 defaultValue={titoloAppuntamenti}
					/>
					{errors?.titoloEditAppuntamenti && (
						<small className="error">{errors.titoloEditAppuntamenti.message}</small>
					)}
				</form>
			</CustomizedDialogs>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}

const HourCard = ({ data, onDelete, canEdit }) => {
	const { day, hours } = data;
	return (
		<Card
			style={{
				width: "100%",
				margin: "5px 0",
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			<CardContent style={{ padding: 10 }}>
				<div>
					<Typography style={{ textTransform: "capitalize" }}>
						{moment().day(day).format("dddd")}
					</Typography>
				</div>
				{hours.map((hour, index) => (
					<div key={index}>
						<Typography>
							{moment(hour.startDate).format("HH:mm")} -{" "}
							{moment(hour.endDate).format("HH:mm")}
						</Typography>
					</div>
				))}
			</CardContent>
			{canEdit && (
				<CardActions>
					<IconButton
						aria-label="delete"
						color="primary"
						onClick={() => onDelete(day)}
					>
						<DeleteIcon />
					</IconButton>
				</CardActions>
			)}
		</Card>
	);
};
