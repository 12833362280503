import React from "react";
import "./App.css";
import Drawer from "./components/Drawer/";
import Login from "./pages/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerPermissions } from "./store/actions/auth";
import { useSession } from "react-session-persist";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";

moment.locale("it");

function App() {
	const dispatch = useDispatch();
	const { authenticated } = useSession();
	const permissions = useSelector(state => state.permissions);

	React.useEffect(() => {
		const getPermissions = async () => {
			if (!permissions) {
				dispatch(registerPermissions());
			}
		};
		if (authenticated) getPermissions();
	}, [authenticated, dispatch, permissions]);

	return (
		<>
			<Switch>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/forgot-password">
					<ForgotPassword />
				</Route>
				<Route path="/">
					{authenticated ? (
						<MuiPickersUtilsProvider
							utils={MomentUtils}
							libInstance={moment}
							locale="it"
						>
							<Drawer />
						</MuiPickersUtilsProvider>
					) : (
						<Redirect to="/login" />
					)}
				</Route>
			</Switch>
		</>
	);
}

export default App;
