import React from "react";
import MaterialTable from "material-table";
import { icons } from "./icons/icons";

export default function MaterialTableDemo({
	data,
	columns,
	title,
	editable = true,
	actions = [],
}) {
	const [state, setState] = React.useState({
		columns: columns,
		data: data,
	});

	return (
		<MaterialTable
			title={title}
			icons={icons}
			columns={state.columns}
			data={state.data}
			options={{
				search: true,
				sorting: true,
				filtering: true,
				actionsColumnIndex: -1,
				pageSize: 10,
				exportButton: true,
			}}
			actions={actions}
			localization={{
				pagination: {
					labelDisplayedRows: "{from}-{to} di {count}",
					labelRowsSelect: "righe",
				},
				toolbar: {
					nRowsSelected: "{0} righe selezionate",
					searchPlaceholder: "Cerca",
				},
				header: {
					actions: "Azioni veloci",
				},
				body: {
					emptyDataSourceMessage: "Nessun record da mostrare",
					filterRow: {
						filterTooltip: "Filtra",
					},
				},
			}}
			editable={
				editable
					? {
							onRowUpdate: (newData, oldData) =>
								new Promise(resolve => {
									setTimeout(() => {
										resolve();
										if (oldData) {
											setState(prevState => {
												const data = [...prevState.data];
												data[data.indexOf(oldData)] = newData;
												return { ...prevState, data };
											});
										}
									}, 600);
								}),
					  }
					: {}
			}
		/>
	);
}
