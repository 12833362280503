import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "../../components/Box";
import Avatar from "@material-ui/core/Avatar";
import ProfilePlaceHolder from "../../assets/avatar-placeholder.png";
import { getSession } from "react-session-persist";
import TextField from "@material-ui/core/TextField";
import { purple } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import { editPassword } from "../../services/User";
import CustomizedDialogs from "../../components/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useForm } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: 500,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& button[type=submit]": {
      marginTop: theme.spacing(2),
    },
  },
  textInput: {
    marginBottom: theme.spacing(2),
  },
  mainBox: {
    width: "300px",
    "& strong": {
      color: theme.palette.grey[700],
    },
  },
  CenterContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  ProfileData: {
    paddingTop: theme.spacing(1),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "300px",
  },
}));

export default function Profilo() {
  const classes = useStyles();
  const session = getSession();
  const { register, handleSubmit, watch, errors } = useForm();
  const watchPassword = watch("newPassword", false);
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState({
    old: false,
    new: false,
    confirm: false,
  });

  const ColorButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(purple[400]),
      backgroundColor: purple[400],
      "&:hover": {
        backgroundColor: purple[600],
      },
    },
  }))(Button);

  const changePassword = async data => {
    setOpen(true);
    setLoading(true);
    try {
      await editPassword(data.oldPassword, data.newPassword);
      setResult(true);
      setLoading(false);
    } catch (err) {
      console.error("err", err);
      setResult(false);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="xl">
      <div className={classes.header}>
        <Typography variant="h4">Profilo</Typography>
      </div>
      <div className={classes.container}>
        <div className={classes.mainBox}>
          <Box padding={15} internalTitle="Dettagli Profilo" spaced>
            <div className={classes.CenterContent}>
              <Avatar
                className={classes.large}
                alt="ProfileImage"
                src={ProfilePlaceHolder}
              />
              <div className={classes.ProfileData}>
                <Typography variant="h6">{session.username}</Typography>
              </div>
              <div>
                <Typography variant="subtitle2">{session.email}</Typography>
              </div>
            </div>
          </Box>
          <Box padding={15} internalTitle="Modifica password" spaced>
            <form
              className={classes.form}
              onSubmit={handleSubmit(changePassword)}
              noValidate>
              <TextField
                name="oldPassword"
                classes={{ root: classes.textInput }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "La password è obbligatoria",
                  },
                })}
                label="Vecchia password"
                variant="standard"
                type={show.old ? "text" : "password"}
                fullWidth
                error={errors.oldPassword !== undefined}
                helperText={errors.oldPassword?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShow({
                            ...show,
                            old: !show.old,
                          })
                        }>
                        {show.old ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                name="newPassword"
                classes={{ root: classes.textInput }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "La nuova password è obbligatoria",
                  },
                  minLength: {
                    value: 8,
                    message: "La password deve contenere minimo 8 caratteri",
                  },
                })}
                label="Nuova password"
                variant="standard"
                error={errors.newPassword !== undefined}
                type={show.new ? "text" : "password"}
                helperText={errors.newPassword?.message}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShow({
                            ...show,
                            new: !show.new,
                          })
                        }>
                        {show.new ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                name="confirmPassword"
                classes={{ root: classes.textInput }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "La nuova password è obbligatoria",
                  },
                  minLength: {
                    value: 8,
                    message: "La password deve contenere minimo 8 caratteri",
                  },
                  validate: value =>
                    watchPassword === value || "Le password non coincidono",
                })}
                label="Conferma password"
                variant="standard"
                error={errors.confirmPassword !== undefined}
                type={show.confirm ? "text" : "password"}
                helperText={errors.confirmPassword?.message}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShow({
                            ...show,
                            confirm: !show.confirm,
                          })
                        }>
                        {show.confirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ColorButton type="submit" variant="contained">
                Modifica password
              </ColorButton>
            </form>
          </Box>
        </div>
      </div>
      <CustomizedDialogs open={open} onClose={handleClose} title={"Esito"}>
        {!loading ? (
          result ? (
            "Password modificata con successo"
          ) : (
            "Errore durante la modifica della password. Verificare la vecchia password e riprovare"
          )
        ) : (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </CustomizedDialogs>
    </Container>
  );
}
