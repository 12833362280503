import * as React from "react";
import {
	Scheduler,
	WeekView,
	Appointments,
	AppointmentForm,
	AppointmentTooltip,
	DragDropProvider,
	Toolbar,
	DateNavigator,
	TodayButton,
	AllDayPanel,
	Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
	ViewState,
	EditingState,
	IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import BooleanEditor from "./BooleanEditor";
import BasicLayout from "./BasicLayout";
import DateEditor from "./DateEditor";

const styles = theme => ({
	button: {
		color: theme.palette.background.default,
		padding: 0,
	},
	text: {
		paddingTop: theme.spacing(1),
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

const AppointmentBase = ({
	children,
	data,
	onClick,
	classes,
	toggleVisibility,
	onAppointmentMetaChange,
	...restProps
}) => (
	<Appointments.Appointment
		{...restProps}
		onClick={({ target }) => {
			toggleVisibility();
			onAppointmentMetaChange({
				target: target.parentElement,
				data,
			});
		}}
	>
		{children}
	</Appointments.Appointment>
);

const Appointment = withStyles(styles, { name: "Appointment" })(
	AppointmentBase
);

export default class SchedulerComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		const {
			schedulerData,
			onChange,
			onUpdate,
			resources,
			currentDate,
		} = this.props;
		this.count = 0;
		this.state = {
			data: schedulerData,
			mainResourceName: "members",
			resources: resources,
			visible: false,
			appointmentMeta: {
				target: null,
				data: {},
			},
			addedAppointment: { new: true, categoria: 1 },
			currentDate: currentDate,
		};

		this.toggleVisibility = () => {
			const { visible: tooltipVisibility } = this.state;
			this.setState({ visible: !tooltipVisibility });
		};
		this.onAppointmentMetaChange = ({ data, target }) => {
			this.setState({ appointmentMeta: { data, target } });
		};
		this.onCommitChanges = ({ added, changed, deleted }) => {
			let data = [];
			if (added) {
				const startingAddedId = this.state.data.length;
				data = [
					...this.state.data,
					{ id: startingAddedId, confirmed: false, ...added },
				];
				added = { [startingAddedId]: { ...added } };
				onChange({ added, changed, deleted });
			}
			if (changed) {
				data = this.state.data.map(appointment =>
					changed[appointment.id]
						? { ...appointment, ...changed[appointment.id] }
						: appointment
				);
				onChange({ added, changed, deleted });
			}
			if (deleted !== undefined) {
				data = this.state.data.filter(
					appointment => appointment.id !== deleted
				);
				onChange({ added, changed, deleted });
			}
			onUpdate(data);
		};
		this.onAddedAppointmentChange = appointment => {
			this.setState({
				addedAppointment: {
					...this.state.addedAppointment,
					...appointment,
				},
			});
		};
		this.myAppointment = this.myAppointment.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.schedulerData !== prevProps.schedulerData) {
			this.setState({ data: this.props.schedulerData });
		}
		if (this.props.resources !== prevProps.resources) {
			this.setState({ resources: this.props.resources });
		}
	}

	myAppointment(props) {
		return (
			<Appointment
				{...props}
				toggleVisibility={this.toggleVisibility}
				onAppointmentMetaChange={this.onAppointmentMetaChange}
			/>
		);
	}

	CommandButton = ({ id, ...restProps }) => {
		if (id === "deleteButton") {
			return <AppointmentForm.CommandButton id={id} {...restProps} />;
		}
		return <AppointmentForm.CommandButton id={id} {...restProps} />;
	};

	render() {
		const {
			data,
			appointmentMeta,
			visible,
			currentDate,
			addedAppointment,
			mainResourceName,
			resources,
		} = this.state;

		const {
			className,
			filter,
			canEdit,
			canDelete,
			schedulerData,
			onDateChange,
		} = this.props;
		let filteredData = data;

		if (filter) {
			filteredData = schedulerData.filter(el =>
				filter.every(value => el.members.includes(value))
			);
		}

		return (
			<Paper className={className} elevation={0}>
				<Scheduler data={filteredData} height="auto" locale="it-IT">
					<ViewState
						currentDate={currentDate}
						onCurrentDateChange={date => {
							onDateChange(date);
							this.setState({ currentDate: date });
						}}
					/>

					{canEdit && (
						<EditingState
							onCommitChanges={this.onCommitChanges}
							addedAppointment={addedAppointment}
							onAddedAppointmentChange={this.onAddedAppointmentChange}
						/>
					)}

					<Toolbar />

					<TodayButton messages={{ today: "OGGI" }} />

					<DateNavigator />

					<WeekView startDayHour={9} endDayHour={19} />

					{canEdit && <IntegratedEditing />}

					<Appointments appointmentComponent={this.myAppointment} />

					<AppointmentTooltip
						showDeleteButton={canDelete}
						showOpenButton={canEdit}
						showCloseButton
						visible={visible}
						onVisibilityChange={this.toggleVisibility}
						appointmentMeta={appointmentMeta}
						onAppointmentMetaChange={this.onAppointmentMetaChange}
					/>

					<AllDayPanel messages={{ allDay: "Tutto il giorno" }} />

					<AppointmentForm
						commandButtonComponent={this.CommandButton}
						booleanEditorComponent={BooleanEditor}
						basicLayoutComponent={BasicLayout}
						messages={{
							moreInformationLabel: "Note sull'appuntamento",
							notesLabel: "Note",
							detailsLabel: "Titolo dell'evento",
						}}
						dateEditorComponent={DateEditor}
						resourceEditorComponent={() => <></>}
					/>

					<Resources data={resources} mainResourceName={mainResourceName} />

					{canEdit && <DragDropProvider />}
				</Scheduler>
			</Paper>
		);
	}
}
