import React from "react";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";

const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
	const onCustomFieldChange = nextValue => {
		onFieldChange({ categoria: nextValue });
	};
	const options = [
		{
			id: 1,
			text: "Occupato",
		},
		{
			id: 2,
			text: "Appuntamento con cittadino",
		},
		{
			id: 3,
			text: "Ferie",
		},
		{
			id: 4,
			text: "Fuori sede",
		},
	];

	return (
		<AppointmentForm.BasicLayout
			appointmentData={appointmentData}
			onFieldChange={onFieldChange}
			{...restProps}
		>
			<AppointmentForm.Label
				text="Tipologia"
				type="title"
				style={{ margin: "10px 0 5px 0" }}
			/>
			<AppointmentForm.Select
				type="filledSelect"
				availableOptions={options}
				readOnly={!appointmentData.new && appointmentData.categoria === 2}
				onValueChange={onCustomFieldChange}
				value={appointmentData.categoria}
			/>
		</AppointmentForm.BasicLayout>
	);
};

export default BasicLayout;
