import axios from "axios";
import { getSession } from "react-session-persist";

export const login = (username, password) => {
	return axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/login`,
		JSON.stringify({ username: username, password: password }),
		{
			headers: {
				"Content-Type": "application/json",
				section: "segnalazioni",
			},
		}
	);
};

export const resetPassword = email => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/resetPassword`,
		JSON.stringify({ email: email }),
		{
			headers: {
				"Content-Type": "application/json",
				section: "utenti",
			},
		}
	);
};

export const refresh = () => {
	const session = getSession();
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/refreshToken`,
		{
			headers: {
				Authorization: `Bearer ${session.refreshToken}`,
			},
		}
	);
};

export const getPermissions = async () => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/getPermissions`
	);
};

export const getUsers = async (category, section) => {
	const session = getSession();
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/autenticazione/utentiConPermessi/${category}?permission=2`,
		{
			headers: {
				Authorization: `Bearer ${session.token}`,
				section: section,
			},
		}
	);
};
