import axios from "axios";
import { getSession } from "react-session-persist";

export const getTickets = async () => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/segnalazioni/segnalazioni`
	);
};

export const getTicket = async id => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/segnalazioni/segnalazioni?id=${id}`
	);
};

export const getAttachment = async (attachmentId, category) => {
	const session = getSession();
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/segnalazioni/allegati/${category}?id=${attachmentId}`,
		{
			headers: {
				Authorization: `Bearer ${session.token}`,
			},
			responseType: "blob",
		}
	);
};

export const updateStatus = async (id, stato, note = "", category) => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/segnalazioni/segnalazioni/${category}/updateStatus?id=${id}`,
		{
			stato: stato,
			nota: note,
		}
	);
};

export const assignTicket = async (category, ticketId, operatorId) => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/segnalazioni/segnalazioni/${category}/assegna?id=${ticketId}`,
		{
			operatore: operatorId,
		}
	);
};
