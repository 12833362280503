import axios from "axios";
import { getSession } from "react-session-persist";

export const getTickets = async () => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/comunitaenergetica/richieste`
	);
};

export const getTicket = async id => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/comunitaenergetica/richieste?id=${id}`
	);
};

export const getAttachment = async attachmentId => {
	const session = getSession();
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/comunitaenergetica/allegati?id=${attachmentId}`,
		{
			headers: {
				Authorization: `Bearer ${session.token}`,
			},
			responseType: "blob",
		}
	);
};

export const updateStatus = async (id, stato) => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/comunitaenergetica/richieste/updateStatus?id=${id}`,
		{
			stato: stato,
		}
	);
};
