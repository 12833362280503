import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	img: {
		maxHeight: 500,
		maxWidth: 500,
	},
});

export default function ImageCarousel({ data }) {
	if (data) {
		return (
			<Carousel indicators autoPlay={false} navButtonsAlwaysVisible>
				{data.map(item => (
					<Item item={item} key={item.id} />
				))}
			</Carousel>
		);
	}
	return <Carousel></Carousel>;
}

function Item({ item }) {
	const { url } = item;
	const classes = useStyles();
	return (
		<Paper>
			<img src={url} className={classes.img} alt="img" />
		</Paper>
	);
}
