import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getTickets } from "../../services/Segnalazioni/";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container } from "@material-ui/core";
import Datatable from "../../components/Datatable/";
import { useHistory } from "react-router-dom";
import ViewListIcon from "@material-ui/icons/ViewList";
import moment from "moment";
import { useSelector } from "react-redux";
import { updateStatus } from "../../services/Segnalazioni";

const useStyles = makeStyles(theme => ({
	title: {
		margin: 0,
	},
	tableContainer: {
		padding: "20px 0",
	},
	progressContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

export default function Segnalazioni() {
	const classes = useStyles();
	const history = useHistory();
	const permissions = useSelector(state => state.permissions);
	const [data, setData] = useState();
	const columns = [
		{
			title: "Data d'apertura",
			field: "dataCreazione",
			editable: "never",
			defaultSort: "desc",
			render: rowData => moment(rowData.dataCreazione).format("DD/MM/YYYY"),
		},
		{ title: "Categoria", field: "categoria", editable: "never" },
		{ title: "Sottocategoria", field: "sottoCategoria", editable: "never" },
		{ title: "Operatore", field: "operatore.username", editable: "never" },
		{
			title: "Stato",
			field: "stato",
			lookup: {
				0: "APERTA",
				1: "IN ATTESA",
				2: "PRESA IN CARICO",
				3: "IN LAVORAZIONE",
				4: "CONCLUSA CON SUCCESSO",
				5: "CONCLUSA CON INSUCCESSO",
				7: "GIA' RIPARATO",
				8: "NON DI COMPETENZA",
			},
		},
	];
	useEffect(() => {
		const fetchData = async () => {
			const response = await getTickets();
			setData(response.data);
		};
		fetchData();
	}, []);

	if (data && permissions) {
		return (
			<Container maxWidth="xl">
				<div className={classes.tableContainer}>
					<Datatable
						data={data}
						columns={columns}
						title="Segnalazioni"
						editable={false}
						editableAction={{
							onRowUpdate: (newData, oldData) =>
								new Promise(async resolve => {
									await updateStatus(newData._id, newData.stato);
									//console.log(oldData, newData);
									setTimeout(() => {
										resolve();
									}, 1000);
								}),
						}}
						actions={[
							{
								icon: () => <ViewListIcon />,
								tooltip: "Dettaglio segnalazione",
								isFreeAction: false,
								onClick: (event, rowData) => {
									history.push(`/segnalazioni/${rowData._id}`);
								},
							},
						]}
					/>
				</div>
			</Container>
		);
	}

	return (
		<Container classes={{ root: classes.progressContainer }} maxWidth="xl">
			<CircularProgress />
		</Container>
	);
}
